/* Scrollbar */

::-webkit-scrollbar {
  width: 15px; /* Track width */
}

::-webkit-scrollbar-track {
  background: transparent; /* Track color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--translucentHard); /* Scrollbar color */
  border-radius: 20px;
  border: 3px solid transparent; /* Creates spacing */
  background-clip: padding-box; /* Ensures the border doesn’t overlap the thumb */
}

::-webkit-scrollbar-thumb:hover {
  /* background: var(--color); */
}

.chat-groups-panel::-webkit-scrollbar {
  width: 30px; /* Track width */
}

.chat-groups-panel::-webkit-scrollbar-track {
  background: transparent; /* Track color */
  border-radius: 10px;
}

.chat-groups-panel::-webkit-scrollbar-thumb {
  background: var(--translucentHard); /* Scrollbar color */
  border-radius: 20px;
  border: 10px solid transparent; /* Creates spacing */
  background-clip: padding-box; /* Ensures the border doesn’t overlap the thumb */
}

.chat-groups-panel::-webkit-scrollbar-thumb:hover {
  /* background: var(--color); */
}

@media (max-width: 900px) {
  ::-webkit-scrollbar {
    width: 5px; /* Track width */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* Track color */
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--secondarySurface); /* Scrollbar color */
    border-radius: 20px;
    border: none; /* Creates spacing */
    background-clip: padding-box; /* Ensures the border doesn’t overlap the thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    /* background: var(--color); */
  }
}
